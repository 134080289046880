
import { Box, Button, Grid, IconButton, Link } from '@mui/material';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import TwitterIcon from '@mui/icons-material/Twitter';
import GoogleIcon from '@mui/icons-material/Google';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';

function Header() {
    return (
        <>
            <Box sx={{ pt: 2, pb: 1 }}>
                <Grid
                    container
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                >
                    <Grid item>
                        <Link href="https://www.facebook.com/profile.php?id=100086428620014" target="_blank">
                            <IconButton color="primary">
                                <FacebookIcon fontSize="small" />
                            </IconButton>
                        </Link>
                        <Link href="https://www.instagram.com/expresstransfer.wirral" target="_blank">
                            <IconButton color="primary">
                                <InstagramIcon fontSize="small" />
                            </IconButton>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Link sx={{ verticalAlign: 'middle' }} href="mailto:expresstransfer.wirral@gmail.com" target="_blank">
                            <Button sx={{ fontSize: '9px' }} variant="text" startIcon={<EmailIcon />}>
                                expresstransfer.wirral@gmail.com
                            </Button>
                        </Link>
                        <Link sx={{ verticalAlign: 'middle' }} href="tel:447554745924">
                            <Button sx={{ fontSize: '9px' }} variant="text" startIcon={<PhoneIcon />}>
                                +447554745924
                            </Button>
                        </Link>
                        <Link sx={{ verticalAlign: 'middle' }} href="tel:01513386405">
                            <Button sx={{ fontSize: '9px' }} variant="text" startIcon={<PhoneIcon />}>
                                0151 338 6405
                            </Button>
                        </Link>


                    </Grid>
                </Grid>
            </Box>
        </>
    );
}

export default Header;